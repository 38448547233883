import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";
import AffiliateGuard from "@/modules/settings/helpers/AffiliateGuard";
import PortfolioGuard from "@/modules/settings/helpers/PortfolioGuard";

export default [
  {
    path: "/settings",
    beforeEnter: OnboardingGuard.withCompleteOnboarding,
    children: [
      {
        path: "",
        name: "Settings",
        redirect: { name: "Account" },
      },
      {
        path: "account",
        name: "Account",
        component: () => import("@/modules/settings/views/TheAccount.vue"),
        meta: {
          title: "modules.settings.account.meta",
          layout: "SettingsLayout",
        },
      },
      {
        path: "notifications",
        name: "NotificationSettings",
        component: () =>
          import("@/modules/settings/views/NotificationSettings.vue"),
        meta: {
          title: "modules.settings.notifications.meta",
          layout: "SettingsLayout",
        },
      },
      {
        path: "payout-method",
        name: "PayoutMethod",
        component: () => import("@/modules/settings/views/PayoutMethod.vue"),
        meta: {
          title: "modules.settings.payoutMethod.meta",
          layout: "SettingsLayout",
        },
      },
      {
        path: "wallet",
        name: "Wallet",
        component: () => import("@/modules/settings/views/TheWallet.vue"),
        meta: {
          title: "modules.settings.wallet.meta",
          layout: "SettingsLayout",
        },
      },
      {
        path: "certifications",
        name: "Certifications",
        component: () =>
          import("@/modules/settings/views/TheCertifications.vue"),
        meta: {
          title: "modules.settings.certifications.meta",
          layout: "SettingsLayout",
        },
      },
      {
        path: "affiliate-program",
        name: "AffiliateProgram",
        beforeEnter: AffiliateGuard.getStats,
        component: () =>
          import("@/modules/settings/views/AffiliateProgram.vue"),
        meta: {
          title: "modules.settings.affiliate.meta",
          layout: "SettingsLayout",
        },
      },
      {
        path: "portfolio",
        name: "PortfolioSummary",
        component: () =>
          import("@/modules/settings/views/PortfolioSummary.vue"),
        beforeEnter: PortfolioGuard.withDraft,
        meta: {
          title: "modules.settings.portfolio.meta",
          layout: "SettingsLayout",
        },
      },
      {
        path: "reviews",
        name: "Reviews",
        component: () => import("@/modules/settings/views/TheReviews.vue"),
        meta: {
          title: "modules.settings.reviews.meta",
          layout: "SettingsLayout",
        },
      },
      {
        path: "request-feature",
        name: "RequestAFeature",
        meta: {
          title: "modules.settings.requestFeature.meta",
        },
        redirect: () => {
          window.open(
            "https://joinbrands-ugc.canny.io/feature-requests",
            "_blank"
          );
          return { name: "Dashboard" };
        },
      },
    ],
  },
  {
    path: "/certification",
    children: [
      {
        path: ":certificationId(\\d+)/details",
        name: "Certification",
        component: () =>
          import("@/modules/settings/views/TheCertification.vue"),
        beforeEnter: OnboardingGuard.withCompleteOnboarding,
        meta: {
          title: "modules.settings.certifications.meta",
        },
      },
    ],
  },
];
