import type { ApiServiceErrorValidationRule } from "@/core/shared/services/Error/ApiServiceError";

import i18n from "@/i18n";

export class ApiErrorValidationTranslator {
  static translate(
    ruleName: string,
    rule: ApiServiceErrorValidationRule
  ): string {
    if (ruleName === "max_string_chars") {
      return i18n.global.t("common.error.validation.maxStringChars", rule.data);
    }
    if (ruleName === "greater_than_18_years_old") {
      return i18n.global.t("common.error.validation.olderThan18", rule.data);
    }
    if (ruleName === "unique") {
      return i18n.global.t("common.error.validation.unique");
    }
    if (ruleName === "file_too_large") {
      return i18n.global.t("common.error.validation.fileTooLarge", {
        maxSize: rule.data.max_size,
      });
    }
    if (ruleName === "image_insufficient_resolution") {
      const data = rule.data;
      return i18n.global.t(
        "common.error.validation.imageInsufficientResolution",
        {
          requiredWidth: data.required[0],
          requiredHeight: data.required[1],
          detectedWidth: data.detected[0],
          detectedHeight: data.detected[1],
        }
      );
    }
    if (ruleName === "video_insufficient_resolution") {
      const data = rule.data;
      return i18n.global.t(
        "common.error.validation.videoInsufficientResolution",
        {
          requiredWidth: data.required[0],
          requiredHeight: data.required[1],
          detectedWidth: data.detected[0],
          detectedHeight: data.detected[1],
        }
      );
    }
    if (ruleName === "not_duration_required") {
      const data = rule.data;
      return i18n.global.t("common.error.validation.notDurationRequired", {
        min: data.min,
        max: data.max,
      });
    }
    if (ruleName === "invalid_image_orientation") {
      return i18n.global.t("common.error.jobContent.imageOrientation", {
        requiredOrientation: rule.data.required,
      });
    }
    if (ruleName === "invalid_video_orientation") {
      return i18n.global.t("common.error.jobContent.videoOrientation", {
        requiredOrientation: rule.data.required,
      });
    }
    if (ruleName === "video_insufficient_length") {
      const data = rule.data;
      const secondsLabel = i18n.global.t(
        "modules.job.uploadJobContent.content.video.units.seconds"
      );
      const minutesLabel = i18n.global.t(
        "modules.job.uploadJobContent.content.video.units.minutes"
      );
      let required = `${data.required} ${secondsLabel}`;
      if (data.required === 60) {
        required = `1-3 ${minutesLabel}`;
      } else if (data.required === 180) {
        required = `3-5 ${minutesLabel}`;
      } else if (data.required === 300) {
        required = `5-10 ${minutesLabel}`;
      } else if (data.required === 600) {
        required = `10+ ${minutesLabel}`;
      }
      const detected = `${data.detected} ${secondsLabel}`;
      return i18n.global.t("common.error.jobContent.videoInsufficientLength", {
        requiredLength: required,
        detectedLength: detected,
      });
    }
    if (ruleName === "video_aspect_ratio") {
      const data = rule.data;
      return i18n.global.t("common.error.validation.videoAspectRatio", {
        required: data.required.join(", "),
        detected: data.detected[0],
      });
    }
    if (ruleName === "file_extension_not_allowed") {
      const data = rule.data;
      return i18n.global.t("common.error.validation.fileExtensionNotAllowed", {
        format: data.allowed_extensions,
      });
    }
    if (ruleName === "file_too_big") {
      return i18n.global.t("common.error.validation.fileTooBig");
    }
    if (ruleName === "unable_to_process") {
      return i18n.global.t("common.error.validation.unableToProcess");
    }

    return rule.message;
  }
}
