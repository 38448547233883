import AccountGuard from "@/core/modules/account/helpers/AccountGuard";
import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";

export default [
  {
    path: "/",
    children: [
      {
        path: "login",
        name: "Login",
        component: () => import("@/core/modules/account/views/TheLogin.vue"),
        meta: {
          title: "core.modules.account.login.meta",
          layout: "AccountLayout",
        },
        beforeEnter: AuthGuard.withoutToken,
      },
      {
        path: "two-factor-authentication/:id(\\d+)?",
        name: "TwoFactorAuthentication",
        component: () =>
          import("@/core/modules/account/views/TwoFactorAuthentication.vue"),
        meta: {
          title: "core.modules.account.twoFactorAuthentication.meta",
          layout: "AccountLayout",
        },
        beforeEnter: [
          AuthGuard.withToken,
          AccountGuard.withoutTwoFactorAuthentication,
          AccountGuard.withTwoFactorAuthenticationCode,
        ],
      },
      {
        path: "two-factor-authentication/methods",
        name: "VerificationMethods",
        component: () =>
          import("@/core/modules/account/views/VerificationMethods.vue"),
        meta: {
          title: "core.modules.account.verificationMethods.meta",
          layout: "AccountLayout",
        },
        beforeEnter: AuthGuard.withToken,
      },
      {
        path: "logout",
        name: "Logout",
        component: () => import("@/core/modules/account/views/TheLogout.vue"),
        meta: {
          title: "core.modules.account.logout.meta",
          layout: "AccountLayout",
        },
        beforeEnter: AuthGuard.withToken,
      },
      {
        path: "register",
        name: "Register",
        component: () => import("@/core/modules/account/views/TheRegister.vue"),
        meta: {
          title: "core.modules.account.register.meta",
          layout: "AccountLayout",
        },
        beforeEnter: AuthGuard.withoutToken,
      },
      {
        path: "forgot-password",
        name: "ForgotPassword",
        component: () =>
          import("@/core/modules/account/views/ForgotPassword.vue"),
        meta: {
          title: "core.modules.account.forgotPassword.meta",
          layout: "AccountLayout",
        },
        beforeEnter: AuthGuard.withoutToken,
      },
      {
        path: "reset-password/:code",
        name: "ResetPassword",
        component: () =>
          import("@/core/modules/account/views/ResetPassword.vue"),
        meta: {
          title: "core.modules.account.resetPassword.meta",
          layout: "AccountLayout",
        },
        beforeEnter: AuthGuard.withoutToken,
      },
      {
        path: "verify-email",
        name: "VerifyEmail",
        component: () => import("@/core/modules/account/views/VerifyEmail.vue"),
        meta: {
          title: "core.modules.account.verifyEmail.meta",
          layout: "AccountLayout",
        },
        beforeEnter: AuthGuard.withUnverifiedEmail,
      },
      {
        path: "verify-email/:code",
        name: "VerifyEmailCode",
        component: () =>
          import("@/core/modules/account/views/VerifyEmailCode.vue"),
        meta: {
          title: "core.modules.account.verifyEmailCode.meta",
          layout: "AccountLayout",
        },
        beforeEnter: AuthGuard.withoutVerifiedEmail,
      },
      {
        path: "settings/account/verify-email/:code",
        name: "VerifyUpdateEmailCode",
        component: () =>
          import("@/core/modules/account/views/VerifyUpdateEmailCode.vue"),
        meta: {
          title: "core.modules.account.verifyEmailCode.meta",
          layout: "AccountLayout",
        },
      },
      {
        path: "confirm-email",
        name: "ConfirmEmail",
        component: () =>
          import("@/core/modules/account/views/ConfirmEmail.vue"),
        meta: {
          title: "core.modules.account.confirmEmail.meta",
          layout: "AccountLayout",
        },
        beforeEnter: AuthGuard.withoutEmail,
      },
      {
        path: "account/impersonate/:token",
        name: "Impersonate",
        component: () =>
          import("@/core/modules/account/views/ImpersonateLogin.vue"),
        meta: {
          title: "core.modules.account.impersonate.meta",
          layout: "AccountLayout",
        },
        beforeEnter: AccountGuard.withImpersonationToken,
      },
    ],
  },
];
