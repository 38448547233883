import { toRef } from "vue";

import { type DsIAuthService, useDsConnectivity } from "@devsalsa/vue-core";

import { ApiErrorHandler } from "@/core/shared/helpers/Error/ApiErrorHandler";

import type { InternalAxiosRequestConfig } from "axios";
import type { AxiosProgressEvent } from "axios";
import type { AxiosRequestHeaders, AxiosResponse, Method } from "axios";
import axios, { AxiosError } from "axios";
import type { IAxiosRetryConfig } from "axios-retry";
import axiosRetry from "axios-retry";
import { container } from "tsyringe";

/**
 * This interface describes the response from the API.
 *
 * The data object could vary for each endpoint, explaining the "any" type.
 *
 * @interface ApiResponse
 * @memberOf ApiService
 * @property data {T} Data received from the API
 * @property ts {number} Timestamp for the response
 */
// eslint-disable-next-line
export interface ApiResponse<T = any> {
  data: T;
  ts: number;
}

/**
 * This interface describes the response from the API.
 *
 * The data object could vary for each endpoint, explaining the "any" type.
 *
 * @interface ApiSuccessResponse
 * @memberOf ApiService
 */
// eslint-disable-next-line
export interface ApiSuccessResponse<T = any> extends ApiResponse {}

/**
 * This interface describes the response from the API.
 *
 * The data object could vary for each endpoint, explaining the "any" type.
 *
 * @interface ApiErrorResponse
 * @memberOf ApiService
 * @property code {string} Error code sent by the API
 * @property message {string} Error message sent by the API
 */
// eslint-disable-next-line
export interface ApiErrorResponse<T = any> extends ApiResponse {
  code: string;
  message: string;
}

/**
 * @class ApiService
 */
export class ApiService {
  static readonly POST = "post";
  static readonly GET = "get";
  static readonly PUT = "put";
  static readonly DELETE = "delete";

  /**
   * Fetch data using axios
   * @param method {string} Method (post, get, put, delete)
   * @param endpoint {string} URL Endpoint
   * @param data {any} Body params
   * @param headers {AxiosRequestHeaders} HTTP Headers
   * @param axiosRetryConfig Define the retry strategy
   * @param onRetry Callback when a retry is started
   * @param onUploadProgress
   * @param controller
   * @return {Promise<ApiSuccessResponse>}
   */
  static async fetchData<ApiResponse>(
    method: Method,
    endpoint: string,
    data?: Record<string, unknown> | FormData,
    headers?: AxiosRequestHeaders,
    axiosRetryConfig?: IAxiosRetryConfig,
    onRetry?: (retryCount: number) => void,
    onUploadProgress?: (event: AxiosProgressEvent) => void,
    controller?: AbortController
  ): Promise<ApiResponse> {
    const isOnline = toRef(useDsConnectivity(), "isOnline");
    const { setOnline, setOffline } = useDsConnectivity();
    const client = axios.create();
    if (axiosRetryConfig) {
      client.interceptors.request.use((config: InternalAxiosRequestConfig) => {
        // axios-retry doesn't provide a full description of properties available.
        // eslint-disable-next-line
        const retryRequestData = (<any>config)["axios-retry"];
        if (retryRequestData) {
          const retryCount = retryRequestData.retryCount;
          if (retryCount > 0 && onRetry === undefined) {
            setOffline();
          } else if (retryCount > 0 && typeof onRetry === "function") {
            onRetry(retryCount);
          }
        }

        return config;
      });

      client.interceptors.response.use(
        (response: AxiosResponse) => {
          if (!isOnline.value) {
            //Enable connectivity if we have response without error
            setOnline();
          }
          return response;
        },
        (error: AxiosError) => {
          if (!isOnline.value && error.request.status > 0) {
            //Enable connectivity if we have response with any error
            setOnline();
          }
          throw error;
        }
      );

      axiosRetry(client, axiosRetryConfig);
    }
    const authService = container.resolve<DsIAuthService>("AuthService");
    const token = authService.getToken();
    const __headers = { ...headers } as AxiosRequestHeaders;
    if (token) {
      Reflect.set(__headers, "Authorization", `Bearer ${token}`);
    }
    return client
      .request({
        method: method,
        url: import.meta.env.VITE_APP_API_URL + endpoint,
        data: data,
        headers: __headers,
        onUploadProgress: onUploadProgress,
        signal: controller?.signal,
      })
      .then((response: AxiosResponse) => {
        return response.data.data;
      })
      .catch((error: AxiosError) => {
        ApiErrorHandler.handle(error);
      });
  }

  /**
   * Get
   * @param endpoint {string} Endpoint
   * @param onRetry Callback when a retry is started
   * @return {Promise<ApiSuccessResponse>}
   */
  static get<ApiResponse>(
    endpoint: string,
    onRetry?: (retryCount: number) => void
  ): Promise<ApiResponse> {
    return ApiService.fetchData(
      ApiService.GET,
      endpoint,
      undefined,
      {} as AxiosRequestHeaders,
      {
        retries: 10,
        retryDelay: (retryCount): number => {
          //Intervals in seconds
          const intervals = [5, 5, 10, 15, 30, 60, 180, 300, 600, 1200, 1800];
          return intervals[retryCount] * 1000;
        },
        retryCondition: (error): boolean => {
          // Only Network Error
          return error.code === AxiosError.ERR_NETWORK;
        },
      },
      onRetry
    );
  }

  /**
   * Post
   * @param endpoint {string} Endpoint
   * @param data {any} Body params
   * @param headers {AxiosRequestHeaders}
   * @param onUploadProgress
   * @param controller
   * @return {Promise<ApiSuccessResponse>}
   */
  static post<ApiResponse>(
    endpoint: string,
    data?: Record<string, unknown> | FormData,
    headers?: AxiosRequestHeaders,
    onUploadProgress?: (event: AxiosProgressEvent) => void,
    controller?: AbortController
  ): Promise<ApiResponse> {
    return ApiService.fetchData(
      ApiService.POST,
      endpoint,
      data,
      headers,
      undefined,
      undefined,
      onUploadProgress,
      controller
    );
  }

  /**
   * Put
   * @param endpoint {string} Endpoint
   * @param data {any} Body params
   * @param headers {AxiosRequestHeaders}
   * @return {Promise<ApiSuccessResponse>}
   */
  static put<ApiResponse>(
    endpoint: string,
    data?: Record<string, unknown> | FormData,
    headers?: AxiosRequestHeaders
  ): Promise<ApiResponse> {
    return ApiService.fetchData(ApiService.PUT, endpoint, data, headers);
  }

  /**
   * Delete
   * @param endpoint {string} Endpoint
   * @param data
   * @return {Promise<ApiSuccessResponse>}
   */
  static delete<ApiResponse>(
    endpoint: string,
    data?: Record<string, unknown> | FormData
  ): Promise<ApiResponse> {
    return ApiService.fetchData(ApiService.DELETE, endpoint, data);
  }
}
